<template>
  <nav>
    <ul>
      <li><i class="fas fa-bars"></i></li> 
      <li>Currículum</li>
      <li>Sobre Mi</li>
      <li>Aficiones</li>
      <li>Contacto</li>
    </ul>
  </nav>
</template>

<script>
export default {
	name: 'CrNav'	
}
</script>